function createHaiku() {
  console.clear()
  console.log(`

         
        &                
       / \\            \\_|_/           \\_|_/
      (   )          (     )  {} {}  (     )
     (     )         ( (.) )__{}_{}__( (.) )
    (       )        (     )         (     )
   (  ______ )       (                     )
   (__|_||_|_)        (                   )
   (         )         (        v        )
   (    W    )          (            .  )
   (.........)           (             )
   (. ~~~~~ .)            (   \\___/   )
    (... ...)              (         )
     (.. ..)                (       )
      (...)                  (     )
       \\_/               (o)> (   )
        |       #       (  )   ( )
        |      # #       /\\     |
<=------|----------=x=----------|------=>
        |                       o
        |                       |
        |                       |
        |                       |
        |                       |
       / \\                     / \\
      /   \\                   /   \\
     /     \\                 /     \\
    /       \\               /       \\
   /         \\             /         \\
       

  🄽🄾🅃🄴 🅃🄾 🅈🄾🅄

_._███████████████ _
_ █████████████████ \\_
 ███████████████████  \\__     
█████████████████████    \\__   Let's get more flowers
_█________▄▄▄▄_▄▄▄▄_█          
_█__█████_▐▓▓▌_▐▓▓▌_█ 
_█__█████_▐▓▓▌_▐▓▓▌_█ 
_█__███=█_▐▓▓▌_▐▓▓▌_█ 
_█__█████_▀▀▀▀_ ▀▀▀_█ ✿ ✿ 
_█__█████___________█(\\|/) 
_____________██ ___________██ "I can write a haiku." I said
_____________█ ____________██ "I'm sure you can" he said
______________█ ___________██ And that's how you were born:
_______________██ _________██ 
___ ✿ ✿ ________██ _______   Feisty, kind and warm
___(\\|/)___________██ _____   World needs more fire not war
__________________██ ______   Dedicate your heart
_________________███ ______██ 
______________████ ________██ You know how I am
___________█████ __________██ I'll make it better if I can
_________██████ ___________██ Let's get started then
_______██████ _____________██ - Yagmur

`)
}

export { createHaiku }
